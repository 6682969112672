<template>
  <div class="carmaintenanceinformation">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="车牌号码">
            <el-input v-model="form.carNum" placeholder="请输入车牌号码" clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="addDialogFormVisible = true">添加</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="carNum" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="modifyOrgan" label="维修机构" align="center"></el-table-column>
          <el-table-column prop="modifyTime" label="维修时间" align="center"></el-table-column>
          <el-table-column prop="modifyMoney" label="维修费用" align="center"></el-table-column>
          <el-table-column prop="modifyImg" label="维修发票" align="center"></el-table-column>
          <el-table-column prop="modifyReceiver" label="责任人" align="center"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10,15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
        <span slot="title" class="dialog-Title">{{dioTotle}}</span>
        <el-form
          :model="selectForm"
          inline
          ref="selectForm"
          :rules="selectFormRules"
          class="selectForm"
        >
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车牌号码" prop="carNum">
              <el-input v-model="selectForm.carNum" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="维修机构" prop="modifyOrgan">
              <el-input v-model="selectForm.modifyOrgan" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="维修时间" prop="modifyTime">
                <el-date-picker v-model="selectForm.modifyTime" type="datetime" placeholder="选择维修时间"></el-date-picker>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="维修费用" prop="modifyMoney">
              <el-input v-input-limit v-model="selectForm.modifyMoney" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
            <!-- <el-form-item label="维修发票" prop="modifyImg">
              <el-input v-model="selectForm.modifyImg" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item> -->
            <el-form-item label="责任人" prop="modifyReceiver">
              <el-input
                v-model="selectForm.modifyReceiver"
                autocomplete="off"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="selectForm.remark" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加车辆维修记录</span>
        <el-form :model="addForm" inline ref="addForm" class="addForm" :rules="addFormRules">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车牌号码" prop="carNum">
              <el-input v-model="addForm.carNum" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="维修机构" prop="modifyOrgan">
              <el-input v-model="addForm.modifyOrgan" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="维修时间" prop="modifyTime">
                <el-date-picker v-model="addForm.modifyTime" type="datetime" placeholder="选择维修时间"></el-date-picker>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="维修费用" prop="modifyMoney">
              <el-input v-input-limit v-model="addForm.modifyMoney" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="责任人" prop="modifyReceiver">
              <el-input v-model="addForm.modifyReceiver" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addForm.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="维修发票" prop="modifyImg" v-model="addForm.modifyImg">
              <el-upload
                action="#"
                list-type="picture-card"
                :auto-upload="false"
                :on-change="handleChange"
                :file-list="fileList"
                ref="upload"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file,fileList)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 附件预览弹出框 -->
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'carmaintenanceinformation',
  components: {
  },
  data () {
    return {
      dioTotle: '',
      tabData: [], // 表格数据源
      form: { carNum: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {}, // 添加表单
      // is: true,
      addFormRules: {
        carNum: [
          { required: true, message: '请输入车牌号码', trigger: 'blur' }
        ],
        modifyOrgan: [
          { required: true, message: '请输入维修机构', trigger: 'blur' }
        ],
        modifyMoney: [
          { required: true, message: '请输入维修费用', trigger: 'blur' }
        ],
        modifyTime: [
          { required: true, message: '请选择维修时间', trigger: 'blur' }
        ]
      }, // 添加表单校验规则
      selectFormRules: {
        carNum: [
          { required: true, message: '请输入车牌号码', trigger: 'blur' }
        ],
        modifyOrgan: [
          { required: true, message: '请输入维修机构', trigger: 'blur' }
        ],
        modifyMoney: [
          { required: true, message: '请输入维修费用', trigger: 'blur' }
        ],
        modifyTime: [
          { required: true, message: '请选择维修时间', trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [{ label: '正常', status: 1 }, { label: '停用', status: 2 }]
    }
  },
  created () {
    this.getAllCar()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取车辆维修信息
    async getAllCar () {
      const { data: res } = await this.$http.post('/pmService/tInfoVehicleModify/getTInfoVehicleModifyList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo
        // "status": 1
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取车辆维修列表失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      if (this.form.carNum === '') {
        this.getAllCar()
      } else {
        // 调接口，进行查询
        this.paging.PageNo = 1
        const { data: res } = await this.$http.post('/pmService/tInfoVehicleModify/getTInfoVehicleModifyList', {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          carNum: this.form.carNum
        })
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        this.tabData = res.data.records
        this.total = +res.data.total
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
      this.dioTotle = '车辆维修详情'
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
      this.dioTotle = '修改车辆维修信息'
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除${e.carNum}`, '删除车辆', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const { data: res } = await this.$http.post('/pmService/tInfoVehicleModify/deleteTInfoVehicleModify', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) { return this.orderDialogFormVisible = false } else {
        this.selectForm.modifyTime = this.formatTime(this.selectForm.modifyTime, 'yyyy-MM-dd hh:mm:ss')
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          // 进行编辑操作
          const { data: res } = await this.$http.post('/pmService/tInfoVehicleModify/updateTInfoVehicleModify', JSON.parse(JSON.stringify(this.selectForm)))
          console.log(res)
          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 格式化时间
    formatTime (date, fmt) {
      var date = new Date(date)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      var o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          var str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
        }
      }
      return fmt
    },
    // 添加机构--确定按钮
    addSure () {
      // console.log(JSON.parse(JSON.stringify(this.addForm)));
      this.addForm.modifyTime = this.formatTime(this.addForm.modifyTime, 'yyyy-MM-dd hh:mm:ss')
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        console.log(JSON.parse(JSON.stringify(this.addForm)))
        // 调接口，发送添加客户请求
        const { data: res } = await this.$http.post('/pmService/tInfoVehicleModify/addTInfoVehicleModify', JSON.parse(JSON.stringify(this.addForm)))
        console.log(res)
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.fileList = []
      this.$refs.addForm.resetFields()
    },
    handleRemove (file) {
      const fileList = this.$refs.upload.uploadFiles
      const index = fileList.findIndex(fileItem => {
        return fileItem.uid === file.uid
      })
      fileList.splice(index, 1)
      const i = this.fileList.findIndex(fileItem => {
        return fileItem.uid === file.uid
      })
      this.fileList.splice(i, 1)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 添加附件时触发
    handleChange (file, fileList) {
      if (fileList.length > 3) {
        this.$message.warning('最多上传三张图片')
        fileList.splice(fileList.length - 1, 1)
      } else {
        this.fileList.push(file)
        this.addForm.file = this.fileList
      }
    }
  }
}
</script>
<style lang="less" scoped>
.carmaintenanceinformation {
  width: 100%;
  height: 100%;
  //隐藏滚动条
//   ::-webkit-scrollbar {
//     display: none; /* Chrome Safari */
//   }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
